.feed-card {
    display: flex;
    align-items: flex-start;
    padding: 1em;
    border: 1px solid #f9f9f9;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin: 30px 10px;
    overflow: scroll;
  }
  
  .avatar {
    width: 48px;
    height: 48px;
    margin-right: 1em;
    flex-shrink: 0;
  }
  
  .avatar-image {
    width: 100%;
    height: 100%;
  }

  .avatar img {
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .feed-content {
    flex-grow: 1;
  }
  
  .feed-header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }
  
  .username {
    font-weight: bold;
    margin-right: 6px;
  }
  
  .handle {
    color: #777;
    margin-right: 6px;
  }
  
  .time {
    color: #777;
  }
  
  .feed-summary {
    margin-bottom: 12px;
    text-align: left;
  }
  
  .chart-placeholder {
    padding: 12px;
    border: 1px #ccc;
    border-radius: 8px;
    background-color: white;
    text-align: center;
    font-style: italic;
    color: #777;

  }
  