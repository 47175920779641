* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  background-color: #f8f8f8;
}

header {
  background-color: #d81e5b;
  color: #fff;
  padding: 1em;
  text-align: center;
}

h1 {
  font-size: 3em; /* increase the font size to 3em */
  margin: 0;
}

main {
  max-width: 800px;
  margin: 0 auto;
  padding: 2em 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cta-button-secondary {
  background-color: #becfd054;
  border: none;
  color: balck;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button {
  background-color: rgb(14, 152, 176);
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: rgb(7, 108, 126);
}
/*
.card {
  width: 31%;
  margin-bottom: 1em;
  padding: 1.5em;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}

.card ul {
  color: darkgray;
}
.card:hover {
  transform: translateY(-5px);
}*/
main {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.card1 {
  margin-bottom: 40px;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.card1.appear {
  opacity: 1;
  transform: translateY(0);
}

.card1 h4 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color:rgb(14, 152, 176);
}

ul {
  font-size: 1em;
  line-height: 1.5;
  color: rgba(152, 154, 155, 0.769);
  margin: 0;
  padding-left: 20px;
}

.heading {
  font-size: 60px;
  font-family: 'Raleway', Arial, sans-serif;
  font-weight: 700;
  color: #163c68;
}
.sub-heading {
  font-size: 18px;
  font-family: Arial, sans-serif;
  line-height: 1.5;
  color: rgb(67, 67, 114);
  margin: 20px 0;
  padding: 20px;
}

h2 {
  font-size: 1.5em; /* increase the font size to 1.5em */
  margin-bottom: 1em;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 1em;
}

hr {
  border-top: 1px solid rgb(141, 140, 140);
}

footer1 {
  background-color: rgb(202, 10, 170);
  color: #fff;
  padding: 1em;
  text-align: center;
}

footer p {
  font-size: 0.8em;
  margin: 0;
}

.gardient-bg-1 {
  background: rgb(245, 244, 243);
  background: radial-gradient(circle, #ffffff 100%, #f4f1f1 22%);
}
.round-corners-10px {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.round-corners-16px {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.unround-bottom-corners {
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -moz-border-radius-bottomright: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.circle:hover {
  background: linear-gradient(#950b60, #1e1014) padding-box,
    linear-gradient(45deg, #1e1014, #d81e5b) border-box;
  border: 1px solid transparent;
  border-radius: 50px;
}

.circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #d81e5b;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.circle-hollow {
  width: 55px;
  height: 55px;
  line-height: 46px;
  border-radius: 55%;
  border: 3px solid #d81e5b;
  font-size: 12px;
  color: rgb(27, 27, 27);
  text-align: center;
  background: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gradient-border-bg {
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, #d1d1d1, #ede9ea) border-box;
  border: 5px solid transparent;
  border-radius: 50px;
}

.circle-banner {
  width: 200px;
  height: 200px;
  line-height: 20px;
  border-radius: 50%;
  font-size: 15px;
  color: rgb(16, 7, 79);
  text-align: center;
  padding: 50px;
  margin: 10px;
  background: rgba(255, 255, 255, 0.19);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(14.9px);
  -webkit-backdrop-filter: blur(14.9px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.font-blue {
  color: black;
}

.h1-gradient {

}
.gradient-pk{
  background: #AA076B;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #61045F, #AA076B);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #61045F, #AA076B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.gradient-bg {
  background: #005c97; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #363795,
    #005c97
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #363795,
    #005c97
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.gradient-bg-lite1{
  background-color: #89d8d3;
  background-image: linear-gradient(315deg, #89d8d3 0%, #03c8a8 74%);
}
.gradient-bg-lite{
  background:rgb(249, 247, 247)
}
#container {
  margin-top: 20px;
  margin-left: 30px;
  position: relative;
  background: #45453f;
}

.pulsating-circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #8ad007;
  box-shadow: 0 0 0 rgba(150, 70, 70, 0.4);
  animation: pulsate 1.2s ease-in-out infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(130, 112, 112, 0.4);
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.trading-message {

  padding: 30px;
  font-size: 16px;
  line-height: 1.5;
  color: gray;
  border-radius: 10px;
  min-height: 400px;
}

.trading-message p {
  margin-bottom: 10px;
}

.banner-img-gif {
  max-width: 100%;

  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 30rem;
  background-position: center center;
}

.banner-img-steps {
  margin: 10px;

  background-repeat: no-repeat;
  background-size: 60% auto;
  height: 17rem;
  background-position: center center;
}
.order-summary {
  margin: 0 0 0 30px;
  border-radius: 16px;
  background-color: white;
  background-image: url("https://cdn2.iconfinder.com/data/icons/financial-market-trading/64/financial_market_trading_stock_order_app-512.png");
  background-repeat: no-repeat;
  background-size: 20% auto;
  min-height: 525px;
  background-position: center center;
  /* You can also specify other background properties like position and attachment */
}

.filler-data {
  margin: 10px;
  padding: 20px;
  background-image: url("https://cdn2.iconfinder.com/data/icons/financial-market-trading/64/financial_market_trading_stock_order_app-512.png");
  background-repeat: no-repeat;
  background-size: 5% auto;
  height: 300px;
  background-position: center center;
  /* You can also specify other background properties like position and attachment */
}

.loss {
  color: red;
}

.profit {
  color: green;
}
.bg-container {
  position: relative;
  z-index: 0;
}

.bg-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /*background-image: url('https://i1.wp.com/algorithmxlab.com/wp-content/uploads/2019/09/Use-Cases-of-Neural-Networks.jpg?fit=1200%2C704&ssl=1');
  background-size: cover;*/
  opacity: 0.3; /* Adjust the transparency (0.5 = 50% opacity) */
  filter: sepia(100%) hue-rotate(240deg) brightness(1.3) saturate(1.5); /* Apply grayscale filter */
  /* Other overlay styles if needed */
  z-index: -1;
}

@media (max-width: 400px) {
  .bg-container::before {
    background-size: contain;
  }
}
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #fff;
}

.banner-content {
  text-align: center;
}

.banner-box {
  height: 400px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.19);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(14.9px);
  -webkit-backdrop-filter: blur(14.9px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: white;
  height: 365px;
  margin: 10px;
}

.banner-box h1 {
  font-size: 20px;
  text-align: center;
  line-height: 400px; /* vertical centering */
}

.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
}

/*
@media only screen and (max-width: 768px) {
  .card {
    width: 48%;
  }
}

@media only screen and (max-width: 480px) {
  .card {
    width: 100%;
  }
}*/

.moving-underline-container {
  position: relative;
  display: inline-block;
  text-align: center;
}

.moving-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 2px;
  background-color: rgb(5, 211, 5);
  animation: moving-underline-animation 3s linear infinite;
  
}

@keyframes moving-underline-animation {
  0% {
    left: 0;
  }
  30% {
    left: 100%;
  }
  60% {
    left: 0;
  }
}
