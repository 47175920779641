
  body {
    text-align: center;
    margin: auto;
  }
  
  .custcenter {
    display: 'flex';
    justifyContent: 'center';
  }
  
  table {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  
  thead {
    flex: 0 0 auto;
  }
  
  tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  tr {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  
  horizontal-slider {
    width: 100%;
    max-width: 500px;
    height: 100vh;
    margin: auto;
    
  }
  
  .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 10px solid #EF5D28;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }
  
  .example-thumb.active {
    background-color: grey;
  }
  
  .example-track {
    position: relative;
    background: #ddd;
  }
  
  .example-track.example-track-0 {
    background: #1A3177;
  }
  
  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }
  
  .horizontal-slider .example-thumb {
    top: 12px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
  }
  