/* header styles */
header {
  background: #005c97; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #363795,
    #005c97
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #363795,
    #005c97
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 10px;

}
.header-bg {
  background-color: #d9e4f5;
  background-image: linear-gradient(315deg, #d9e4f5 0%, #f5e3e6 74%);
}
/* navigation styles */
nav {
  overflow: hidden;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
/*
nav li {
  float: left;
}
*/

nav ul li {
  position: relative;
  display: inline-block;
  color: rgb(16, 7, 79);
}

nav ul li > ul {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

nav ul li:hover > ul {
  max-height: 100px; /* adjust to fit your submenu content */
}

nav li a {
  display: block;
  color: rgb(20, 1, 1);
  text-align: center;
  padding: 3px 16px;
  text-decoration: none;
}

nav li a:hover {
  background-color: rgb(14, 152, 176);
  color: white;
  border-radius: 5px;
  max-height: 100px;
  cursor: pointer;
}

/* mobile styles */
@media only screen and (max-width: 600px) {
  /* adjust navigation styles */
  nav li {
    float: none;
  }

  nav li a {
    display: block;
    text-align: left;
  }
}

.flip-container {
  -webkit-perspective: 1000;
  width: auto;
  height: 30em;
  margin: 10px;
}

.flipper {
  /*transition: 0.6s;
  -webkit-transform-style: preserve-3d;*/
  position: relative;
  height: 250px;
}

.front,
.back {
  /* From https://css.glass */
  background-color: white;
  background: rgba(255, 255, 255, 0.19);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(14.9px);
  -webkit-backdrop-filter: blur(14.9px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  width: 100%;
  height: 14em;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-backface-visibility: hidden;
  color: #fff;
  text-shadow: 1px 1px #000;
  font-size: 2em;
  line-height: 300px;
  text-align: center;
  overflow: hidden;
}

.back {
  -webkit-transform: rotateY(180deg);
  background: #3498db;
}

.front {
  z-index: 2;
  background: rgba(255, 255, 255, 0.19);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(14.9px);
  -webkit-backdrop-filter: blur(14.9px);
  border: 1px solid rgba(255, 255, 255, 0.31);
}

.flip-container p {
  margin: 10px 0;
  text-align: center;
}

/* Flip Animation */
@keyframes flipX {
  from {
    -webkit-transform: rotateX(180deg);
  }

  to {
    -webkit-transform: rotateX(-180deg);
  }
}
@keyframes flipY {
  from {
    -webkit-transform: rotateY(180deg);
  }

  to {
    -webkit-transform: rotateY(-180deg);
  }
}

.flip-container .flipper {
  animation: flipY 15s infinite;
}

.vertical-flip-container .flipper {
  animation: flipX 4s infinite;
}
.custom-tooltip {
  position: absolute;
  z-index: 1;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 12px;
  max-width: 300px;
  font-family: Arial, sans-serif;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
}