.clock-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: monospace;
    font-size: 24px;
  }
  
  .clock-digit {
    padding: 0 5px;
  }
  
  .clock-separator {
    padding: 0 2px;
  }
  
  .clock-digit,
  .clock-separator {
    background-color: lightgrey;
    color: grey;
    border-radius: 4px;
  }
  
  .clock-label {
    font-size: 14px;
    color: #777;
    margin-top: 4px;
  }
  