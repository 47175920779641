.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #333;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .progress-bar {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .progress-bar::after {
    content: '';
    display: block;
    width: 40%;
    height: 100%;
    background-color: #333;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    animation: progress 1s ease-in-out infinite;
  }
  
  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  